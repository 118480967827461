<template>
  <div class="container">
    <div
      class="row justify-content-center align-content-center"
      style="height: 100vh"
      >
      <div class="flex flex-column center-block col-12 not-found">
        <img class="h-auto" src="@/assets/img/404-black.svg" />
        <div class="flex justify-center items-center">
          <p class="text-center mt-5">
            <span class="label">Page not found</span>
          </p>
        </div>
        <p class="mt-2 text-center"><span><router-link class="link" to="/">Take me home</router-link></span></p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToDashboard () {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  & > img {
    width: 100px;
  }

  text-align: center;
}

.not-found {
  $green-700: #0DAC5C;
  & .label {
    background: $green-700;
    color: #FFFFFF;
    font-weight: bold;
    padding: 0.625rem 2.5rem;
    border-radius: 55px;
  }

  & .link {
    color: $green-700;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
